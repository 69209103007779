import wheel from "../../img/wheel-holderr.png";
import wheelLocale from "../../img/wheel-locale.png";
import roulet from "../../img/roulet.png";
import logo from "../../img/logo.png";
import harly1 from "../../img/harly2.svg";
import elvis1 from "../../img/elvis1.svg";
import arrow from "../../img/arrow.png";

export const Wheel = ({ startWheel, location }) => {
  return (
    <div className="page">
      <div className="page__wrap">
        <a href="" className="logo page__logo">
          <img src={logo} className="logo__img" />
        </a>
        {/* <div className="header-text">
          <h2>
            {location === "CH" || location === "DE" || location === "AT"
              ? "Drehen Sie das Rad und erhalten"
              : "SPIN THE WHEEL AND"}
          </h2>

          <h4>
            {location === "CH" || location === "DE" || location === "AT"
              ? "Sie Freispiele"
              : "SPIN THE WHEEL AND"}
          </h4>
        </div> */}

        <div className="game-layout">
          <img src="" className="game-person gonzo" alt="" />
          <div className="game-layout__body">
            <img src={arrow} alt="arrow" className="arrow" />
            <div className="roulet-wrapper">
              <img src={roulet} alt="roulet" className="roulet" />
            </div>

            <div className="wheel wheel_animated">
              <div className="wheel__spinner-holder">
                <img
                  src={
                    location === "CH" || location === "DE" || location === "AT"
                      ? wheel
                      : wheelLocale
                  }
                  className="wheel__spinner wheel__spinner_animated"
                />

                <div className="wheel__mask"></div>
                <div className="wheel__button-holder wheel__button-holder_blinking">
                  <div className="wheel__button-pointer">
                    <button
                      className="circle-button wheel__button spin-trigger first_spin"
                      onClick={startWheel}
                    ></button>
                  </div>
                </div>
              </div>
              <div className="wheel__win-frame"></div>
            </div>
          </div>
          <img src={harly1} className="game-person harly" alt="" />
          <img src={elvis1} className="game-person elvis" alt="" />
        </div>

        {/* <div className="page__footer">
          <div className="payments"></div>
          <div className="bg-gradion"></div>
          <div className="text">
            <img src={footerLogo} alt="logo" />

            <p>
              Copyright © 2020 abocasino.com is owned and operated by Hollycorn
              N.V., a company registered and established under the laws of
              Curaçao, with registration number 144359 and registered address at
              Heelsumstraat 51 E-Commerce Park , Curaçao. Libergos Ltd. is a
              subsidiary of Hollycorn N.V., acting as an Agent on behalf of
              Hollycorn N.V., registered in Cyprus with registered address
              Boumpoulinas, 1-3, BOUBOULINA BUILDING, Flat/Office 42, 1060,
              Nicosia, Cyprus, and registration number ΗΕ 371971. It is the
              player’s sole responsibility to inquire about the existing laws
              and regulations of the given jurisdiction for online gambling.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};
