export const baseUrl = "https://www.arlekincasino.com/";
export const linkUrl = "https://arlekincasino20.com/";
export const baseApiUrl = `${baseUrl}api/`;

export const request = async (path, method, jsonVersion, body) => {
  const result = await fetch(`${baseApiUrl}${path}`, {
    method: method || "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Accept: jsonVersion
        ? `application/vnd.softswiss.v${jsonVersion}+json`
        : "application/json",
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
  });

  if (result.status === 204) {
    return null;
  }

  try {
    return await result.json();
  } catch (e) {
    return null;
  }
};

export const httpOptions = (headers) => ({
  method: "GET",
  mode: "cors",
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    ...(headers ?? {}),
  },
});
